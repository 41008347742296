import React from 'react';

import BottomDescription, {
  TFooterV2BottomDescriptionProps,
} from './BottomDescription';
import FooterLinks, { TFooterLinksProps } from './FooterLinks';
import { Newsletter, TNewsletterProps } from './Newsletter';
import { Socials, TSocialsProps } from './Socials';
import SupportCards, { TSupportCardsProps } from './SupportCards';
import { Awards, TAwardsProps } from '../../atoms/Awards';
import { Container, EContainerVariant } from '../../sections/Container';

export type TFooterV2Props = {
  newsletter: TNewsletterProps | null;
  socials?: TSocialsProps | null;
  awards?: TAwardsProps;
  bottom: TFooterV2BottomDescriptionProps;
  links?: TFooterLinksProps['linkGroups'];
  supportCards?: TSupportCardsProps['cards'];
};

const FooterV2: React.FC<TFooterV2Props> = props => {
  const { newsletter, socials, awards, bottom, links, supportCards } = props;

  return (
    <footer>
      <div className='bg-surface-100'>
        {links && (
          <Container variant={EContainerVariant.Main}>
            <FooterLinks linkGroups={links} className='pt-12' />
          </Container>
        )}
        <Container variant={EContainerVariant.Main}>
          <div className='flex flex-col gap-20 py-12 lg:flex-row lg:flex-wrap lg:gap-x-10 lg:gap-y-[3.25rem] xl:justify-between xl:gap-x-32 xl:gap-y-17 2xl:gap-x-[7.5rem]'>
            {!!newsletter && (
              <Newsletter
                title={newsletter.title}
                description={newsletter.description}
                newsletterFormComponent={newsletter.newsletterFormComponent}
              />
            )}
            {socials && (
              <Socials
                title={socials.title}
                description={socials.description}
                socialMedia={socials.socialMedia}
                iconStyle={socials.iconStyle}
              />
            )}
            {!!supportCards?.length && <SupportCards cards={supportCards} />}
          </div>
        </Container>
      </div>
      {!!awards?.list?.length && (
        <div className='overflow-hidden bg-surface-100 py-12 md:pb-[2.2rem]'>
          <Container isPadding={false} variant={EContainerVariant.Main}>
            <Awards list={awards?.list} title={awards?.title} />
          </Container>
        </div>
      )}
      <div className='bg-surface-150 py-8'>
        <Container variant={EContainerVariant.Main}>
          <BottomDescription
            legalText={bottom?.legalText}
            description={bottom.description}
            leftLinks={bottom?.leftLinks}
            rightLinks={bottom?.rightLinks}
            copyright={bottom?.copyright}
            moreText={bottom?.moreText}
            contacts={bottom?.contacts}
          />
        </Container>
      </div>
    </footer>
  );
};

export default FooterV2;
