import clsx from 'clsx';
import React from 'react';

import MenuItem, { TMenuItemProps } from './MenuItem';
import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../../atoms/Eyebrow';

export type TFooterLinksProps = {
  linkGroups: {
    title: string;
    menuItems: TMenuItemProps[];
  }[];
  className?: string;
};

const FooterLinks: React.FC<TFooterLinksProps> = ({
  linkGroups,
  className,
}) => {
  return (
    <div
      className={clsx(
        'flex flex-wrap items-start justify-start gap-[48px] md:gap-x-[106px] md:gap-y-12 lg:gap-x-[148px] lg:gap-y-12 xl:gap-8 2xl:gap-[61px]',
        className,
      )}
    >
      {linkGroups.map(({ title, menuItems }, i) => (
        <div
          className='flex w-full flex-col items-start justify-start gap-4 md:w-[251px] xl:w-[224px] 2xl:w-[251px]'
          key={`${title}-${i}`}
        >
          <div className='flex w-full items-center justify-start border-b border-surface-200 pb-3'>
            <Eyebrow
              variant={EEyebrowVariant.TextTracking}
              size={EEyebrowSize.Medium}
              className='block truncate text-interface-500'
            >
              {title}
            </Eyebrow>
          </div>
          <div className='flex w-full flex-col items-start justify-start gap-2'>
            {menuItems.map((menuItem, index) => (
              <MenuItem key={index} {...menuItem} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FooterLinks;
