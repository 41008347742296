import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { EActionType, TAction } from '../../../types/actions';
import { Button } from '../../atoms/Button';
import { DropdownMenu } from '../../atoms/DropdownMenu';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder/ImageWithPlaceholder';

export type TMenuItemProps = {
  action: TAction;
  isDropdownItem?: boolean;
};

const MenuItem: React.FC<TMenuItemProps> = ({ action, isDropdownItem }) => {
  const scrollBarClasses =
    'scrollbar scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1 scrollbar-thin scrollbar-thumb-surface-200 scrollbar-track-surface-50 scroll-smooth';
  return (
    <div className='flex h-10 w-full items-center transition-opacity hover:opacity-80 active:opacity-80'>
      {action.type === EActionType.LinksGroupType && (
        <DropdownMenu
          label={action.label || ''}
          className='w-full !gap-2 !px-0 !text-base !leading-loose !text-control-950 outline-none'
          labelClassName='overflow-hidden text-ellipsis whitespace-nowrap'
          dropdownClassName={clsx(
            '!max-h-46 !w-[19.75rem] !p-4 !text-base',
            scrollBarClasses,
          )}
          closeOnOutsideClick
        >
          {action.actions?.map((actionItem, i) => (
            <MenuItem key={i} action={actionItem} isDropdownItem />
          ))}
        </DropdownMenu>
      )}
      {action.type === EActionType.ButtonType && (
        <Button
          {...action.props}
          className='h-10 rounded-3xl bg-brand-500 text-sm font-medium leading-normal text-control-950 hover:bg-brand-500/80'
        >
          {action.props.children}
        </Button>
      )}
      {(action.type === EActionType.LinkType || !action.type) && (
        <Link
          {...action.props}
          legacyBehavior
          className='inline-flex w-full items-center gap-2'
        >
          {}
          <a
            className={clsx(
              'flex w-full items-center font-medium leading-loose text-control-950 outline-none aria-disabled:cursor-not-allowed aria-disabled:opacity-40',
              {
                'pointer-events-none cursor-not-allowed opacity-40':
                  action.props.disabled,
              },
            )}
          >
            <span
              className={clsx('display-block overflow-hidden py-2', {
                'text-ellipsis whitespace-nowrap': !isDropdownItem,
              })}
            >
              {action.props.children}
            </span>
            {action.props.trailingIcon && (
              <ImageWithPlaceholder
                {...action.props.trailingIcon}
                className='h-6 max-w-[7.3125rem]'
              />
            )}
          </a>
        </Link>
      )}
    </div>
  );
};

export default MenuItem;
