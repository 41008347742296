import clsx from 'clsx';
import React, { useState } from 'react';

import {
  Button,
  EButtonVariant,
  EButtonTheme,
  EButtonSize,
} from '../../atoms/Button';
import RichText, { TRichTextContent } from '../../atoms/RichText/RichText';

import type { TLeaf } from '../../atoms/RichText/types';
import type { LinkProps } from 'next/link';

type TContact = {
  text: string;
  url?: string;
};

export type TFooterV2BottomDescriptionProps = {
  rightLinks: {
    title: string;
    linkProps: LinkProps;
  }[];
  leftLinks: {
    title: string;
    linkProps: LinkProps;
  }[];
  copyright?: string;
  moreText?: string;
  description?: TLeaf | TLeaf[] | TRichTextContent;
  contacts: TContact[];
  legalText?: string;
};

const BottomDescription: React.FC<TFooterV2BottomDescriptionProps> = props => {
  const {
    leftLinks,
    rightLinks,
    description,
    copyright,
    moreText,
    contacts,
    legalText,
  } = props;
  const bottomLinkContainerClasses = 'flex justify-center flex-wrap gap-6';
  const bottomContactsClasses =
    'text-sm font-normal leading-loose text-control-650';
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const toggleBottomDescription = (): void => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  return (
    <>
      <div className='flex flex-col justify-between gap-12 pb-8 md:flex-row'>
        <div
          className={clsx(
            bottomLinkContainerClasses,
            'md:w-full md:justify-start',
          )}
        >
          {leftLinks.map(leftLink => (
            <Button
              key={leftLink.title}
              variant={EButtonVariant.Text}
              theme={EButtonTheme.Secondary}
              size={EButtonSize.Small}
              {...leftLink.linkProps}
              className='aria-disabled:cursor-not-allowed aria-disabled:opacity-40'
            >
              {leftLink.title}
            </Button>
          ))}
        </div>
        {rightLinks.length > 0 && (
          <div
            className={clsx(
              bottomLinkContainerClasses,
              'md:w-full md:max-w-36 md:justify-end lg:max-w-60 xl:max-w-[20.438rem]',
            )}
          >
            {rightLinks.map(rightLink => (
              <Button
                key={rightLink.title}
                variant={EButtonVariant.Text}
                theme={EButtonTheme.Secondary}
                size={EButtonSize.Small}
                {...rightLink.linkProps}
              >
                {rightLink.title}
              </Button>
            ))}
          </div>
        )}
      </div>
      {description && moreText && (
        <div className='flex flex-col-reverse gap-6 border-t border-surface-200 pt-[1.938rem] xl:flex-row xl:justify-between'>
          <div className='self-center text-sm font-normal leading-mega-loose text-interface-500 md:self-auto'>
            {copyright && <span>{copyright}</span>}
            {!isDescriptionOpen && (
              <span
                className='cursor-pointer'
                onClick={toggleBottomDescription}
              >
                ...&nbsp;
                <span className='text-sm font-medium leading-loose text-control-950'>
                  {moreText}
                </span>
              </span>
            )}
          </div>
          {contacts?.length ? (
            <div className='flex flex-wrap justify-center gap-x-8 gap-y-6 md:justify-between xl:justify-end'>
              {contacts.map(contact => (
                <a
                  key={contact.text}
                  href={contact.url}
                  target='_blank'
                  rel='noreferrer noopener'
                  className={clsx(bottomContactsClasses)}
                >
                  {contact.text}
                </a>
              ))}
            </div>
          ) : null}
        </div>
      )}
      {isDescriptionOpen && (
        <div className='leading-8 pt-4 text-left text-base text-interface-800 empty:hidden'>
          <RichText
            className='prose-p:mt-4 first:prose-p:mt-0 md:mb-8'
            content={description}
          />
        </div>
      )}
      {legalText ? (
        <p className='mt-4 text-sm leading-[18.2px] text-control-650 md:mt-6'>
          {legalText}
        </p>
      ) : null}
    </>
  );
};

export default BottomDescription;
